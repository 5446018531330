import * as Sentry from '@sentry/react'
import React from 'react'
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

const EMAIL_REGEX = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g
const UUID_REGEX =
  /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/g
const ID_REGEX = /\b\d+\b/g
const ID_ORGUNIT_REGEX = /\b\d+_EJBOrgUnit/g

/** Sanitize URL by replacing sensitive information with placeholders. */
const sanitizeUrl = (url: string) =>
  url
    .replace(EMAIL_REGEX, '[email]')
    .replace(UUID_REGEX, '[uuid]')
    .replace(ID_REGEX, '[id]')
    .replace(ID_ORGUNIT_REGEX, '[orgunit]')

const ignorePatters: (string | RegExp)[] = [
  '/api/notification-api/notification',
  '/api/bruker-api/feature-toggles',
]
/** Check if URL should be ignored from Sentry breadcrumbs or spans. */
const ignoreUrl = (url: string) =>
  ignorePatters.some((pattern) => {
    if (typeof pattern === 'string') {
      return url.includes(pattern)
    }

    return pattern.test(url)
  })

const isCanceledError = (exception: Sentry.Exception) => {
  return exception.type === 'CanceledError' && exception.value === 'canceled'
}
const isNetworkError = (exception: Sentry.Exception) => {
  return exception.type === 'AxiosError' && exception.value === 'Network Error'
}
/**
 * The image has not been scaled yet, so we get a 404 error. This is expected and should not be reported to Sentry.
 * The image is saved locally so should not be a problem.
 */
const isBilde404 = (event: Sentry.Event, exception: Sentry.Exception) => {
  const breadcrumbs = Array.from(event.breadcrumbs?.values?.() ?? [])
  const lastBreadcrumb = breadcrumbs[breadcrumbs.length - 1]

  return (
    exception.type === 'AxiosError' &&
    exception.value === 'Request failed with status code 404' &&
    /\/kategorier\/bilder\/\[uuid]\/small/.test(lastBreadcrumb?.data?.url)
  )
}

Sentry.init({
  dsn: 'https://b570a3ec8d501e16d1b7dc950dd83a7f@o4508319312642048.ingest.de.sentry.io/4508358336643152',
  // @ts-ignore This is magically injected by nginx and index.html
  environment: globalThis.SENTRY_APP_ENV ?? 'localhost',
  // The release version of the app
  release: process.env.APP_VERSION,
  enabled: process.env.NODE_ENV === 'production', // disable in localhost
  // Tunnel to send Sentry events through tilsynskvittering-ui-backend to bypass add-blockers and the like
  tunnel: '/api/sentry-tunnel',
  // Enables tracking offline activity
  transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
  integrations: [
    // React Router
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation: useLocation,
      useNavigationType: useNavigationType,
      createRoutesFromChildren: createRoutesFromChildren,
      matchRoutes: matchRoutes,
    }),
    // Replays
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 0.1, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/tilsynskvittering\.inspektor(-(test|utv))?\.mattilsynet\.io/,
  ],
  // Session Replay
  // This sets the sample rate at 0. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysSessionSampleRate: 0,
  // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  replaysOnErrorSampleRate: 1.0,

  // Before hooks
  beforeSend: (event) => {
    const exception = event.exception?.values?.[0]
    if (
      exception &&
      (isCanceledError(exception) ||
        isNetworkError(exception) ||
        isBilde404(event, exception))
    ) {
      return null
    }

    if (event.tags?.url) {
      event.tags.url = sanitizeUrl(event.tags.url as string)
    }

    if (event.request?.url) {
      event.request.url = sanitizeUrl(event.request.url)
    }

    return event
  },
  beforeBreadcrumb: (breadcrumb) => {
    if (
      ignoreUrl(breadcrumb.data?.url ?? '') ||
      ignoreUrl(breadcrumb.message ?? '')
    ) {
      // Ignore the breadcrumb
      return null
    }

    if (breadcrumb.type === 'http' && breadcrumb.data?.url) {
      breadcrumb.data.url = sanitizeUrl(breadcrumb.data.url)
    }

    return breadcrumb
  },
  beforeSendTransaction: (transaction) => {
    if (transaction.tags?.url) {
      transaction.tags.url = sanitizeUrl(transaction.tags.url as string)
    }

    if (transaction.request?.url) {
      transaction.request.url = sanitizeUrl(transaction.request.url)
    }

    return transaction
  },
  beforeSendSpan: (span) => {
    if (ignoreUrl(span.description ?? '')) {
      // Ignore the span
      return null
    }

    span.description = sanitizeUrl(span.description ?? '')

    return span
  },
})

// Add global context to Sentry
Sentry.getGlobalScope().setContext('tilsynskvittering', {
  version: process.env.APP_VERSION,
})

export const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter)

/** Constant tag name for custom exception capture throughout the application. */
export const CAPTURE_NAME = 'tkCaptureType' as const
/** Capture type to signify what type of custom exception capture is being sent.
 * What/where in the application that sent the error.
 */
export enum CaptureType {
  ROOT = 'root', // Error in the UI
  ROUTE = 'route', // Error in the route UI
  EPIC = 'epic', // Error in an epic
  MUTATION = 'mutation', // Error in a Tanstack mutation
}

import { AddCircle, Column, IconButton, LoadingSpinner, Row, RowItem, TextLink, Text, LoadingDots, ContrastBox } from '@mattilsynet/mt-ui';
import React, { useCallback, useState } from 'react';
import { Kontrollpunkt } from '../../../features/kontrollpunkter';
import { useTypedSelector } from '../../../common/custom-hooks';
import ImageNavigationModal from '../../../components/image-navigation-modal';
import { Observasjon, useAddObservasjon } from '../../../features/observasjoner';
import { TilsynsobjektMenu } from '../../../components/tilsynsobjekt-menu';
import { observasjonInitialState } from '../../../features/observasjoner/helpers';
import { IStoreState } from '../../../reducers/types';
import { ObservasjonBilder } from '../../../features/bilder';
import { useKvitteringId } from '../../../common/kvittering-context';
import { PrintIcon } from '../../../assets/print';
import { compareSorteringsrekkefoelge, kontrollpunktInitialState } from '../../../features/kontrollpunkter/helpers';
import { useAddKontrollpunkt } from '../../../features/kontrollpunkter/hooks/query-hooks';
import { LabelWithInfo } from '../../../components/label-with-info';
import { IObservasjon } from '../../../features/observasjoner/types';
import { IKontrollpunkt } from '../../../features/kontrollpunkter/types';
import { useTilsynsobjekter } from '../../../features/tilsynsobjekter';
import * as Sentry from '@sentry/react';
const ObservasjonerSlideComponent = () => {
  const [isValid, setIsValid] = useState(false);
  const [shouldCheckFocus, setShouldCheckFocus] = useState(false);
  const [selectedTilsynsobjektId, setSelectedTilsynsobjektId] = useState<string>();
  const kvitteringId = useKvitteringId();
  const {
    data: tilsynsobjekter,
    isLoading
  } = useTilsynsobjekter(kvitteringId);
  const {
    mutate: addKontrollpunkt
  } = useAddKontrollpunkt(kvitteringId);
  const isDesktop = useTypedSelector((state: IStoreState) => state.ui.isDesktop);
  const {
    mutate: addObservasjon,
    isPaused: isPausedAddObservasjon,
    isPending: isPendingAddObservasjon
  } = useAddObservasjon(kvitteringId);
  const selectedTilsynsobjekt = selectedTilsynsobjektId === undefined ? tilsynsobjekter?.[0] : tilsynsobjekter?.find(to => to.id === selectedTilsynsobjektId);
  const onCreateKontrollpunkt = () => {
    if (!selectedTilsynsobjektId) return;
    addKontrollpunkt({
      kontrollpunkt: {
        ...kontrollpunktInitialState(parseInt(selectedTilsynsobjektId, 10))
      }
    });
  };
  const shouldCreateNewObservasjon = (observasjonArray: IObservasjon[]) => {
    const lastObservasjon = observasjonArray[observasjonArray.length - 1];
    return Boolean(lastObservasjon?.tekst);
  };
  const onCreateObservasjon = (kontrollpunkt: IKontrollpunkt) => {
    if (!shouldCreateNewObservasjon(kontrollpunkt.observasjoner) && Boolean(kontrollpunkt.observasjoner.length)) {
      setIsValid(true);
    } else {
      setIsValid(false);
      setShouldCheckFocus(true);
      addObservasjon({
        kontrollpunkt,
        observasjon: observasjonInitialState()
      });
    }
  };
  const onSelectTilsynsobjekt = useCallback((tilsynsobjektId: string) => {
    setSelectedTilsynsobjektId(tilsynsobjektId);
  }, []);
  const onPrint = () => {
    window.print();
  };
  return <>
      <Column spacing={3} fluid key={2} id="observasjoner" data-sentry-element="Column" data-sentry-source-file="observasjoner-slide.tsx">
        <Row justify="space-between" align="center" data-sentry-element="Row" data-sentry-source-file="observasjoner-slide.tsx">
          <RowItem className="title" data-sentry-element="RowItem" data-sentry-source-file="observasjoner-slide.tsx">
            <LabelWithInfo as="h1" label="Observasjoner" data-sentry-element="LabelWithInfo" data-sentry-source-file="observasjoner-slide.tsx">
              <Text data-sentry-element="Text" data-sentry-source-file="observasjoner-slide.tsx">
                En observasjon er hva du ser, lukter, hører, kjenner eller blir
                fortalt under tilsynet. En observasjon skal være objektiv og
                presis. Jo mer alvorlig saken er, desto viktigere er det å ha et
                høyt presisjonsnivå på observasjonene. Personer som ikke var
                tilstede under tilsynet skal ut fra dine observasjoner kunne
                sette seg inn i forholdene på stedet. Det er greit å skrive i
                stikkordsform, for så å skrive mer utfyllende om de samme
                observasjonene i den etterfølgende rapporten.
              </Text>
              <br />
              <Text data-sentry-element="Text" data-sentry-source-file="observasjoner-slide.tsx">
                Du må også notere ned positive observasjoner, slik at
                helhetsbildet av hva som er observert fremkommer. Er det kun
                observert positive forhold, er det derimot ikke nødvendig å
                beskrive faktagrunnlaget så detaljert.
              </Text>
              <br />
              <Text data-sentry-element="Text" data-sentry-source-file="observasjoner-slide.tsx">
                En observasjon er ikke vår vurdering av forholdene eller om
                regelverket er brutt. Det vil si at formuleringer som “i orden”,
                “ok” eller “dårlig inneklima” ikke er egnet.
              </Text>
            </LabelWithInfo>
          </RowItem>
          <RowItem className="no-print print-container" data-sentry-element="RowItem" data-sentry-source-file="observasjoner-slide.tsx">
            <Row align="center" data-sentry-element="Row" data-sentry-source-file="observasjoner-slide.tsx">
              <Text size="medium" data-sentry-element="Text" data-sentry-source-file="observasjoner-slide.tsx">
                {isDesktop ? 'Skriv ut denne siden' : 'Skriv ut'}
              </Text>
              <IconButton onClick={onPrint} icon={<PrintIcon />} large data-sentry-element="IconButton" data-sentry-source-file="observasjoner-slide.tsx" />
            </Row>
          </RowItem>
        </Row>

        <TilsynsobjektMenu tilsynsobjekter={tilsynsobjekter ?? []} selectedTilsynsobjektId={selectedTilsynsobjekt?.id ?? ''} onSelectTilsynsobjekt={onSelectTilsynsobjekt} data-sentry-element="TilsynsobjektMenu" data-sentry-source-file="observasjoner-slide.tsx" />

        {isLoading || selectedTilsynsobjekt === undefined && <Row center>
              <LoadingSpinner title={'Henter observasjoner...'} small />
            </Row>}

        {!isLoading && selectedTilsynsobjekt && <>
            {selectedTilsynsobjekt.kontrollpunkter?.slice().sort(compareSorteringsrekkefoelge).map((kontrollpunkt, kpIndex) => <ContrastBox key={kontrollpunkt.id ?? kpIndex} noBorders backgroundColor="white">
                  <Column margin={[0, 0, 2, 0]}>
                    <Kontrollpunkt kontrollpunktId={kontrollpunkt.id!} index={kpIndex}>
                      {kontrollpunkt => <Column fluid>
                          {kontrollpunkt.observasjoner.map((observasjon, obsIndex, arr) => obsIndex === arr.length - 1 && isPendingAddObservasjon && !isPausedAddObservasjon && observasjon.id < 0 ? <Row key={observasjon.id} justify="center">
                                  <LoadingDots />
                                </Row> : <Observasjon kontrollpunkt={kontrollpunkt} observasjonId={observasjon.id} autofocus={kontrollpunkt.observasjoner.length - 1 === obsIndex && shouldCheckFocus} isValid={isValid} key={observasjon.id}>
                                  {observasjon => <ObservasjonBilder bildeIds={observasjon.bildeIds} observasjonId={observasjon.id} kontrollpunktTitle={kontrollpunkt.beskrivelse} />}
                                </Observasjon>)}

                          <Row justify="center">
                            <TextLink leftIcon={<AddCircle />} onClick={() => onCreateObservasjon(kontrollpunkt)}>
                              Ny observasjon
                            </TextLink>
                          </Row>
                        </Column>}
                    </Kontrollpunkt>
                  </Column>
                </ContrastBox>)}

            <Row justify="center">
              <TextLink leftIcon={<AddCircle />} onClick={onCreateKontrollpunkt}>
                Nytt kontrollpunkt
              </TextLink>
            </Row>
          </>}
      </Column>

      <ImageNavigationModal title="Bilder tilknyttet observasjonen" data-sentry-element="ImageNavigationModal" data-sentry-source-file="observasjoner-slide.tsx" />
    </>;
};
const ObservasjonerSlide = Sentry.withProfiler(ObservasjonerSlideComponent, {
  name: 'ObservasjonerSlide'
});
export { ObservasjonerSlide };
import {
  ERROR,
  ERROR_FETCHING_SAMMENSTILLING,
  IHentetKvittering,
  IKvitteringActions,
  IKvitteringSavingStatus,
  IKvitteringState,
  ITilsynstype,
  NONE,
  OK,
  SAVING,
  IKvitteringData,
} from './types'
import {
  currentKvitteringInitialState,
  tilsynstyperInitialState,
} from './initialState'
import { commonRedux } from '../../common/redux'
import { kvitteringActionTypes } from './actions'
import { veiledningReducer } from './veiledning/reducer'
import { currentTilsynsobjektInitialState } from '../../features/tilsynsobjekter/helpers'

export const initialState: IKvitteringState = {
  loading: false,
  kvitteringer: [],
  selectedKvittering: {
    status: 'NONE',
    data: currentKvitteringInitialState,
  },
  selectedTilsynsobjekt: {
    status: 'NONE',
    data: currentTilsynsobjektInitialState,
  },
  currentKvitteringId: '',
  error: '',
  latestKontrollpunktId: '',
  tempKontrollpunkter: [],
  tilsynstyper: tilsynstyperInitialState,
  hentedeKvitteringer: {
    kvitteringer: [],
    loading: false,
    loaded: false,
    error: '',
  },
}

const kvitteringReducer = commonRedux.createReducer<
  IKvitteringState,
  IKvitteringActions
>(initialState, {
  ...veiledningReducer,
  [kvitteringActionTypes.FETCH_KVITTERINGER]: (state: IKvitteringState) => ({
    ...state,
    ...state,
    loading: true,
  }),
  [kvitteringActionTypes.FETCH_KVITTERINGER_SUCCESS]: (
    state: IKvitteringState,
    { payload }: { payload: IKvitteringData[] }
  ) => ({
    ...state,
    loading: false,
    kvitteringer: payload.map((kvittering) => ({
      ...kvittering,
      synced: true,
    })),
    error: '',
  }),
  [kvitteringActionTypes.FETCH_KVITTERINGER_FAILED]: (
    state: IKvitteringState,
    action: { error: string }
  ) => ({
    ...state,
    loading: false,
    error: action.error,
  }),
  [kvitteringActionTypes.ADD_KVITTERING]: (state: IKvitteringState) => ({
    ...state,
    loading: true,
    error: '',
  }),
  [kvitteringActionTypes.ADD_KVITTERING_SUCCESS]: (
    state: IKvitteringState
  ) => ({
    ...state,
    loading: false,
  }),
  [kvitteringActionTypes.ADD_KVITTERING_FAILED]: (
    state: IKvitteringState,
    { error }: { error: string }
  ) => ({
    ...state,
    loading: false,
    error: error,
  }),
  [kvitteringActionTypes.FETCH_KVITTERING_BY_ID]: (
    state: IKvitteringState
  ) => ({
    ...state,
    loading: true,
    error: '',
  }),
  [kvitteringActionTypes.FETCH_KVITTERING_BY_ID_SUCCESS]: (
    state: IKvitteringState
  ) => ({
    ...state,
    loading: false,
    error: '',
  }),
  [kvitteringActionTypes.FETCH_KVITTERING_BY_ID_FAILED]: (
    state: IKvitteringState,
    action: { error: string }
  ) => ({
    ...state,
    loading: false,
    error: action.error,
  }),
  [kvitteringActionTypes.DELETE_KVITTERING]: (
    state: IKvitteringState,
    action: { kvittering: IKvitteringData }
  ) => ({
    ...state,
    error: '',
    kvitteringer: state.kvitteringer.filter(
      (kvittering: IKvitteringData) => kvittering.id !== action.kvittering.id
    ),
  }),
  [kvitteringActionTypes.DELETE_KVITTERING_FAILED]: (
    state: IKvitteringState,
    action: { error: string }
  ) => ({
    ...state,
    error: action.error,
  }),
  [kvitteringActionTypes.UPDATE_KVITTERING]: (state: IKvitteringState) => ({
    ...state,
    loading: true,
    error: '',
  }),
  [kvitteringActionTypes.UPDATE_KVITTERING_SUCCESS]: (
    state: IKvitteringState,
    action: { kvitteringId?: string; payload: IKvitteringData }
  ) => ({
    ...state,
    kvitteringer: [
      ...state.kvitteringer.map((kvittering) =>
        kvittering.id === action.kvitteringId
          ? { ...kvittering, synced: true }
          : kvittering
      ),
    ],
    loading: false,
  }),
  [kvitteringActionTypes.UPDATE_KVITTERING_FAILED]: (
    state: IKvitteringState,
    action: { error: string }
  ) => ({
    ...state,
    loading: false,
    error: action.error,
  }),
  [kvitteringActionTypes.SET_CURRENT_KVITTERING_FROM_ID]: (
    state: IKvitteringState
  ) => ({
    ...state,
    loading: true,
    error: '',
    selectedKvittering: {
      status: 'NONE',
      data: currentKvitteringInitialState,
    },
  }),
  [kvitteringActionTypes.SET_CURRENT_KVITTERING_FROM_ID_FAILED]: (
    state: IKvitteringState,
    action: { error: string }
  ) => ({
    ...state,
    loading: false,
    error: action.error,
  }),
  [kvitteringActionTypes.SET_CURRENT_KVITTERING]: (
    state: IKvitteringState,
    action: { kvittering: IKvitteringData }
  ) => ({
    ...state,
    loading: false,
    selectedKvittering: {
      ...state.selectedKvittering,
      data: {
        ...action.kvittering,
        'mattilsynet-deltagere':
          action.kvittering['mattilsynet-deltagere'] || [],
        'virksomhet-deltagere': action.kvittering['virksomhet-deltagere'] || [],
        'andre-deltagere': action.kvittering['andre-deltagere'] || [],
      },
    },
  }),
  [kvitteringActionTypes.CLEAR_CURRENT_KVITTERING]: (
    state: IKvitteringState
  ) => ({
    ...state,
    selectedKvittering: {
      status: NONE,
      data: currentKvitteringInitialState,
    },
  }),
  [kvitteringActionTypes.SET_STATUS]: (
    state: IKvitteringState,
    action: { status: IKvitteringSavingStatus }
  ) => ({
    ...state,
    selectedKvittering: {
      ...state.selectedKvittering,
      status: action.status,
    },
  }),
  [kvitteringActionTypes.FETCH_SAMMENSTILLING]: (state: IKvitteringState) => ({
    ...state,
    loading: true,
  }),
  [kvitteringActionTypes.FETCH_SAMMENSTILLING_SUCCESS]: (
    state: IKvitteringState,
    action: { kvittering: IKvitteringData }
  ) => ({
    ...state,
    loading: false,
    kvitteringer: state.kvitteringer.map((k) =>
      k.id === state.selectedKvittering.data.id
        ? {
            ...k,
            _links: state.selectedKvittering.data._links,
          }
        : k
    ),
    selectedKvittering: {
      ...state.selectedKvittering,
      status: OK,
      data: {
        ...action.kvittering,
        orgFormKode: state.selectedKvittering.data.orgFormKode,
        _links: state.selectedKvittering.data._links,
      },
    },
  }),
  [kvitteringActionTypes.FETCH_SAMMENSTILLING_FAILED]: (
    state: IKvitteringState,
    action: { error: string }
  ) => ({
    ...state,
    loading: false,
    error: action.error,
    selectedKvittering: {
      ...state.selectedKvittering,
      status: ERROR_FETCHING_SAMMENSTILLING,
    },
  }),
  [kvitteringActionTypes.UPDATE_CURRENT_KVITTERING]: (
    state: IKvitteringState,
    action: { payload: Partial<IKvitteringData> }
  ) => ({
    ...state,
    kvitteringer: state.kvitteringer.map((k) =>
      k.id === state.selectedKvittering.data.id
        ? {
            ...k,
            ...action.payload,
            synced: window.navigator.onLine,
          }
        : k
    ),
    selectedKvittering: {
      status: SAVING,
      data: {
        ...state.selectedKvittering.data,
        ...action.payload,
      },
    },
  }),
  [kvitteringActionTypes.UPDATE_CURRENT_KVITTERING_SUCCESS]: (
    state: IKvitteringState
  ) => ({
    ...state,
    selectedKvittering: {
      ...state.selectedKvittering,
      status: OK,
    },
  }),
  [kvitteringActionTypes.UPDATE_CURRENT_KVITTERING_FAILED]: (
    state: IKvitteringState,
    action: { error: string }
  ) => ({
    ...state,
    loading: false,
    error: action.error,
    selectedKvittering: {
      ...state.selectedKvittering,
      status: ERROR,
    },
  }),
  [kvitteringActionTypes.ARKIVER_KVITTERING]: (state: IKvitteringState) => ({
    ...state,
    selectedKvittering: {
      ...state.selectedKvittering,
      status: SAVING,
    },
  }),
  [kvitteringActionTypes.FERDIGSTILL_KVITTERING]: (
    state: IKvitteringState
  ) => ({
    ...state,
    selectedKvittering: {
      ...state.selectedKvittering,
      status: SAVING,
    },
  }),
  [kvitteringActionTypes.FETCH_ADRESSE_SUCCESS]: (
    state: IKvitteringState,
    action: { payload: IKvitteringData }
  ) => ({
    ...state,
    kvitteringer: state.kvitteringer.map((k) =>
      k.id === action.payload.id ? action.payload : k
    ),
    selectedKvittering: {
      ...state.selectedKvittering,
      data:
        state.selectedKvittering.data.id === action.payload.id
          ? action.payload
          : state.selectedKvittering.data,
    },
    error: '',
  }),
  [kvitteringActionTypes.FETCH_ADRESSE_FAILED]: (
    state: IKvitteringState,
    action: { error: string }
  ) => ({
    ...state,
    error: action.error,
  }),
  [kvitteringActionTypes.FETCH_VIRKSOMHET_ORGANISASJONSFORM]: (
    state: IKvitteringState
  ) => ({
    ...state,
    loading: true,
  }),
  [kvitteringActionTypes.FETCH_VIRKSOMHET_ORGANISASJONSFORM_SUCCESS]: (
    state: IKvitteringState,
    action: { orgFormKode: string }
  ) => ({
    ...state,
    loading: false,
    error: '',
    kvitteringer: state.kvitteringer.map((k) =>
      k.id === state.selectedKvittering.data.id
        ? {
            ...k,
            orgFormKode: action.orgFormKode,
          }
        : k
    ),
    selectedKvittering: {
      ...state.selectedKvittering,
      data: {
        ...state.selectedKvittering.data,
        orgFormKode: action.orgFormKode,
      },
    },
  }),
  [kvitteringActionTypes.FETCH_VIRKSOMHET_ORGANISASJONSFORM_FAILED]: (
    state: IKvitteringState,
    action: { error: string }
  ) => ({
    ...state,
    loading: false,
    error: action.error,
  }),
  [kvitteringActionTypes.FETCH_TILSYNSTYPER]: (state: IKvitteringState) => ({
    ...state,
    tilsynstyper: {
      ...state.tilsynstyper,
      loading: true,
      loaded: false,
      error: '',
    },
  }),
  [kvitteringActionTypes.FETCH_TILSYNSTYPER_SUCCESS]: (
    state: IKvitteringState,
    action: { tilsynstyper: ITilsynstype[] }
  ) => ({
    ...state,
    tilsynstyper: {
      ...state.tilsynstyper,
      data: action.tilsynstyper,
      loading: false,
      loaded: true,
    },
  }),
  [kvitteringActionTypes.FETCH_TILSYNSTYPER_FAILED]: (
    state: IKvitteringState,
    action: { error: string }
  ) => ({
    ...state,
    tilsynstyper: {
      ...state.tilsynstyper,
      loading: false,
      loaded: false,
      error: action.error,
    },
  }),
  [kvitteringActionTypes.FETCH_KVITTERINGER_FRA_KOLLEGA]: (
    state: IKvitteringState
  ) => ({
    ...state,
    hentedeKvitteringer: {
      kvitteringer: [],
      loading: true,
      loaded: false,
      error: '',
    },
  }),
  [kvitteringActionTypes.FETCH_KVITTERINGER_FRA_KOLLEGA_SUCCESS]: (
    state: IKvitteringState,
    { kvitteringer }: { kvitteringer: IHentetKvittering[] }
  ) => ({
    ...state,
    hentedeKvitteringer: {
      ...state.hentedeKvitteringer,
      kvitteringer: kvitteringer,
      loaded: true,
      loading: false,
    },
  }),
  [kvitteringActionTypes.FETCH_KVITTERINGER_FRA_KOLLEGA_FAIL]: (
    state: IKvitteringState,
    { error }: { error: string }
  ) => ({
    ...state,
    hentedeKvitteringer: {
      kvitteringer: [],
      loading: false,
      loaded: false,
      error: error,
    },
  }),
  [kvitteringActionTypes.SET_CURRENT_KVITTERING_ID]: (
    state: IKvitteringState,
    { kvitteringId }
  ) => ({
    ...state,
    currentKvitteringId: kvitteringId,
  }),
  [kvitteringActionTypes.CLEAR_CURRENT_KVITTERING_ID]: (
    state: IKvitteringState
  ) => ({
    ...state,
    currentKvitteringId: '',
  }),
})

export default kvitteringReducer
